import React from "react";

const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold mb-4">About Us</h2>
      <p className="mb-4">
        Print Hub LLC is a leading provider of high-quality printers and
        comprehensive printing solutions, serving the needs of both individuals
        and businesses. Our extensive selection features a diverse array of
        printers from renowned brands such as HP, Canon, Epson, and more. We are
        dedicated to delivering top-tier printing solutions, not only through
        our superior printers but also through our exceptional customer service.
      </p>
      <p className="mb-4">
        At Print Hub LLC, we understand the challenges involved in choosing the
        perfect printer. That’s why we offer expert guidance to help you find
        the ideal match for your requirements. Our team of experienced
        professionals is fully committed to providing tailored assistance and
        support at every step of your journey, from selecting the right printer
        to seamless setup and efficient troubleshooting.
      </p>
      <p className="mb-4">
        Our pride and commitment to excellence are reflected in the quality of
        our product range. Each printer undergoes rigorous testing to meet our
        high standards of reliability, performance, and overall excellence.
      </p>
      <p>
        Whether you are seeking a printer for personal or business use, Print
        Hub LLC has the knowledge and resources to offer you a flawless
        solution. Choose Print Hub LLC to access top-tier printers and
        unparalleled service quality.
      </p>
    </div>
  );
};

export default AboutUs;
